import { makeRequestOptions, methods } from "./utils";

export const chatService = {
  getChats,
  getMessages,
  addMessage,
  setMessageRead,
  deleteMessage,
};

async function getMessages(receiver_id, page, limit) {
  return makeRequestOptions("GET", `/messages/${receiver_id}?page=${page}&per_page=${limit}`);
}

async function addMessage(receiver_id, message_text, message_uuid) {
  return makeRequestOptions("POST", `/messages/${receiver_id}`, {
    message_text,
    uuid: message_uuid,
  });
}

async function setMessageRead(uuid) {
  return makeRequestOptions(methods.patch, `/read_message/${uuid}`);
}

async function deleteMessage(uuid) {
  return makeRequestOptions(methods.patch, `/delete_message/${uuid}`);
}

async function getChats() {
  return makeRequestOptions(methods.get, "/chats");
}