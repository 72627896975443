import { roleService } from '@/helpers/role.service'

export const state = {
  roles: [],
}

export const actions = {
  async getAll({ commit }) {
    const data = await roleService.getAll()
    commit('getAll', data.roles)
  }
}

export const mutations = {
  getAll(state, roles) {
    state.roles = roles
  }
}

export const getters = {
  getAll(state) {
    return state.roles
  }
}
