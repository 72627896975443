import { analysisService } from '@/helpers/analysis.service'

export const state = {
  analysis: [],
}

export const actions = {
  getAll({ commit }) {
    commit('getAll', analysisService.getAll())
  }
}

export const mutations = {
  getAll(state, analysis) {
    state.analysis = analysis
  }
}

export const getters = {
  getAll(state) {
    return state.analysis
  }
}
