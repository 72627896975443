import { userService } from './user.service'
import { v4 as uuidv4 } from 'uuid'
import Swal from 'sweetalert2'

let deviceToken = localStorage.getItem('deviceToken')
if (!deviceToken) {
    deviceToken = uuidv4()
    localStorage.setItem('deviceToken', deviceToken)
}

export const url = 'https://api-abintel.eastus2.cloudapp.azure.com'
export const tokenApi = '1234567890'
export const baseRequest = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenApi}`,
        'Device-Token': deviceToken,
    },
    credentials: 'include'
}

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text)
        if (!response.ok) {
            const path = window.location.pathname
            if (response.status === 401 && path !== '/logout') {
                // auto logout if 401 response returned from api
                window.location.href = '/logout'
                userService.logout()
            }
            const error = (data && data.message) || response.statusText
            return Promise.reject(error)
        }
        return data
    })
}

export function makeRequestOptions(method, path, body, { formData = false } = {}) {
    const headers = {
        'Authorization': `Bearer ${tokenApi}`,
        'Device-Token': deviceToken,
        'Content-Type': 'application/json'
    }

    if (formData) {
        delete headers['Content-Type']
    }

    const requestOptions = {
        headers,
        credentials: 'include',
        method,
        body: (method !== 'GET' && !formData) ? JSON.stringify(body) : undefined
    };

    const urlWithParams = method === 'GET' && body ? `${url}${path}?${new URLSearchParams(body)}` : `${url}${path}`;

    return fetch(urlWithParams, requestOptions).then(handleResponse);
}


export const methods = {
    post: 'POST',
    get: 'GET',
    put: 'PUT',
    patch: 'PATCH',
    delete: 'DELETE'
}


export function makeRequestOptionsMultiPart(method, path, formData) {
    const headers = {
        'Authorization': `Bearer ${tokenApi}`,
        'Device-Token': deviceToken,
    }

    const requestOptions = {
        headers,
        credentials: 'include',
        method,
        body: formData
    }

    return fetch(`${url}${path}`, requestOptions).then(handleResponse)
}

export const alertDelete = (callback) => {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
    })

    swalWithBootstrapButtons
        .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            showCancelButton: true,
        })
        .then((result) => {
            if (result.value) {
                callback()?.then(() => {
                    swalWithBootstrapButtons.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success"
                    )
                })
                    .catch((error) => {
                        swalWithBootstrapButtons.fire(
                            "Error!",
                            error,
                            "error"
                        )
                        throw error
                    })
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    "Cancelled",
                    "Your data mapping is safe :)",
                    "error"
                )
            }
        })
}

export function transformKBorMBorGB(size) {
    if (size === 0) {
        return '0 KB'
    } else if (size < 1024) {
        return `${(size).toFixed(2)} KB`
    } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} MB`
    } else {
        return `${(size / (1024 * 1024)).toFixed(2)} GB`
    }
}