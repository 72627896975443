<!-- Loading.vue -->
<template>
  <div v-if="loadingState.isLoading" class="loading-overlay">
    <img src="../assets/images/logo.png" alt="Vue logo" class="loading-spinner" />
  </div>
</template>

<script>
import { loadingState } from '../loadingState';

export default {
  setup() {
    return {
      loadingState,
    };
  },
};
</script>

<style>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Fundo obscurecido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 200px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>

