export const analysisService = {
    getAll,
}

function getAll() {
    return [
      {
        analysis_name: 'Analysis 1',
        status: 'Completed',
        created_at: '2021-05-01T00:00:00.000Z',
        members: [
          {
            name: 'Doe',
            url_thumbnail: ''
          },
          {
            name: 'Jane Doe',
            url_thumbnail: 'https://fastly.picsum.photos/id/223/200/200.jpg?hmac=CNNyWbBcEAJ7TPkTmEEwdGrLFEYkxpTeVwJ7U0LB30Y',
          },
          {
            name: 'Jane Doe',
            url_thumbnail: 'https://fastly.picsum.photos/id/223/200/200.jpg?hmac=CNNyWbBcEAJ7TPkTmEEwdGrLFEYkxpTeVwJ7U0LB30Y',
          },
          {
            name: 'Jane Doe',
            url_thumbnail: 'https://fastly.picsum.photos/id/223/200/200.jpg?hmac=CNNyWbBcEAJ7TPkTmEEwdGrLFEYkxpTeVwJ7U0LB30Y',
          },
          {
            name: 'Jane Doe',
            url_thumbnail: '',
          },
        ],
      },
      {
        analysis_name: 'Analysis 2',
        status: 'Out of Delivery',
        created_at: '2022-05-01T00:00:00.000Z',
        members: [
          {
            name: 'Doe',
            url_thumbnail: ''
          },
          {
            name: 'Jane Doe',
            url_thumbnail: 'https://fastly.picsum.photos/id/223/200/200.jpg?hmac=CNNyWbBcEAJ7TPkTmEEwdGrLFEYkxpTeVwJ7U0LB30Y',
          }
        ],
      },
      {
        analysis_name: 'Analysis 3',
        status: 'Inprogress',
        created_at: '2023-05-01T00:00:00.000Z',
        members: [
          {
            name: 'Doe',
            url_thumbnail: ''
          },
          {
            name: 'Jane Doe',
            url_thumbnail: 'https://fastly.picsum.photos/id/223/200/200.jpg?hmac=CNNyWbBcEAJ7TPkTmEEwdGrLFEYkxpTeVwJ7U0LB30Y',
          },
          {
            name: 'Jane Doe',
            url_thumbnail: 'https://fastly.picsum.photos/id/223/200/200.jpg?hmac=CNNyWbBcEAJ7TPkTmEEwdGrLFEYkxpTeVwJ7U0LB30Y',
          },
          {
            name: 'Jane Doe',
            url_thumbnail: 'https://fastly.picsum.photos/id/223/200/200.jpg?hmac=CNNyWbBcEAJ7TPkTmEEwdGrLFEYkxpTeVwJ7U0LB30Y',
          },
          {
            name: 'Jane Doe',
            url_thumbnail: '',
          },
        ],
      }
    ]
}
