<script>
import { useToast } from 'vue-toastification'
export default {
  name: 'Toast',
  setup() {
    return { toast: useToast() }
  },
  methods: {
    openToast(type, message) {
      this.toast[type](message)
    }
  },
  watch: {
    '$store.state.notification.uuid': function () {
      this.openToast(this.$store.state.notification.type, this.$store.state.notification.message)
    }
  }
}
</script>

<template>
  <div>
  </div>
</template>
