import { makeRequestOptions } from './utils'

export const notificationsService = {
  getAll,
  readNotification,
}

async function getAll(user_uuid) {
  return makeRequestOptions('GET', `/notifications?user_receiver_uuid=${user_uuid}`)
}

async function readNotification(notification_uuid) {
  return makeRequestOptions('PATCH', `/notifications/read/${notification_uuid}`)
}
