import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store"

import BootstrapVueNext from 'bootstrap-vue-next'
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts"
import { vMaska } from "maska"

import VueFeather from 'vue-feather'
import Toast from 'vue-toastification'
import {useToast} from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import '@/assets/scss/config/interactive/app.scss'
import '@/assets/scss/mermaid.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import Vueform from '@vueform/vueform'
import vueformConfig from './../vueform.config'

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

const options = {
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
}

const app = createApp(App)
    .use(Toast, options)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .component(VueFeather.type, VueFeather)
    .directive("maska", vMaska)
    .use(i18n)
    .use(vClickOutside)
    .use(Vueform, vueformConfig)

// Adiciona o Toast ao contexto global
app.config.globalProperties.$toast = useToast()

app.mount('#app')