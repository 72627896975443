import { chatService } from "@/helpers/chat.service";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export const state = {
  showOffcanvas: false,
  chatData: [],
  chatMessagesData: [],
  submitted: false,
  message: "",
  username: "",
  currentChatId: "",
  profile: "",
  chats: [],
  onlines: {},
  currentChatCopy: [],
  nextPage: 1,
  limit: 20,
  thereMorePages: true,
  gettingMessages: false,
};

export const actions = {
  async getChats({ commit }) {
    if (this.chats?.length > 0) return;
    chatService.getChats().then((response) => {
      this.chats = response.data;
      commit("setChats", response.data);
    });
  },
  async getMessages({ commit, state }, receiver_id) {
    const chatMessage = state[receiver_id];
    if (!chatMessage) {
      const { data } = await chatService.getMessages(
        receiver_id,
        state.nextPage,
        state.limit
      );
      commit("cleanMessages");
      commit("saveMessages", [receiver_id, data]);
      return data;
    }
  },
  async getOldMessages({ commit, state }) {
    const receiver_id = state.currentChatId;
    if (receiver_id && !state.gettingMessages) {
      commit("setGettingMessages", [true, true]);
      const res = await chatService.getMessages(
        receiver_id,
        state.nextPage,
        state.limit
      );
      commit("addOldMessages", [receiver_id, res.data]);
      setTimeout(() => {
        commit("setGettingMessages", [false, false]);
      }, 2000);
      return res;
    }
  },
  async sendMessage({ commit, state }, message) {
    commit("setMessage", "");
    commit("putOnTop");
    const uuid = uuidv4();
    const receiver_id = state.currentChatId;
    const messageToSet = {
      message,
      align: "right",
      time: moment().format("hh:mm A"),
      status: "sending",
      uuid,
    };
    commit("setChatMessagesData", [messageToSet]);

    chatService
      .addMessage(receiver_id, message, uuid)
      .then((response) => {
        commit("pushMessageOrUpdateMessage", [receiver_id, response.data]);
      })
      .catch(() => {
        commit("setMessage", "Error sending message");
      });
  },
  async setMessageRead({ commit }, uuid) {
    const { data } = await chatService.setMessageRead(uuid);
    if (data) {
      commit("pushMessageOrUpdateMessage", [data.receiver_id, data]);
    }
  },
  async deleteMessage({ commit, dispatch }, uuid) {
    try {
      const { data } = await chatService.deleteMessage(uuid);
      if (data) {
        commit("pushMessageOrUpdateMessage", [data.receiver_id, data]);
        dispatch("notification/sucess", "Message deleted", { root: true });
      }
    } catch (error) {
      dispatch("notification/error", "Error deleting message", { root: true });
    }
  },
};

export const mutations = {
  cleanMessages(state) {
    state.chatData = [];
  },
  pushMessageOrUpdateMessage(state, [receiver_id, message]) {
    if (!state[receiver_id]) {
      state[receiver_id] = [];
    }

    if (state.currentChatId !== receiver_id) {
      const index = state.chats.findIndex(
        (member) => member.uuid === receiver_id
      );
      if (index !== -1) {
        state.chats[index].unread_messages++;
      }
    }

    const index = state[receiver_id].findIndex(
      (msg) => msg.uuid === message.uuid
    );

    if (index !== -1) {
      state[receiver_id][index] = message;
    } else {
      state[receiver_id].push(message);
    }
    const indexUser = state.chats.findIndex(
      (member) => member.uuid === receiver_id
    );

    if (indexUser === -1 || indexUser === 0) {
      return;
    }

    const member = state.chats[indexUser];
    state.chats.splice(indexUser, 1);
    state.chats.unshift(member);
  },
  saveMessages(state, [receiver_id, messages]) {
    if (!state[receiver_id]) state[receiver_id] = [];
    state[receiver_id] = messages;
  },
  addOldMessages(state, [receiver_id, messages]) {
    if (!state[receiver_id]) state[receiver_id] = [];
    state[receiver_id].unshift(...messages);
  },
  setChats(state, chats) {
    state.chats = chats;
  },
  setCurrentChat(state, currentChatId) {
    const res = state.chats.find((member) => member.uuid == currentChatId);

    if (!res) return;
    state.currentChatId = currentChatId;
    state.profile = res.url_thumbnail;
    state.username = res.user_name;

    const index = state.chats.findIndex(
      (member) => member.uuid === currentChatId
    );
    if (index !== -1) {
      state.chats[index].unread_messages = 0;
    }
  },
  setChatMessagesData(state, chatMessagesData) {
    state.chatMessagesData.push(...chatMessagesData);
  },
  handleCanvas(state) {
    state.showOffcanvas = !state.showOffcanvas;
  },
  cleanCurrentChat(state) {
    state.currentChatId = "";
    state.profile = "";
    state.username = "";
  },
  putOnTop(state) {
    const indexUser = state.chats.findIndex(
      (member) => member.uuid === state.currentChatId
    );

    const member = state.chats[indexUser];
    state.chats.splice(indexUser, 1);
    state.chats.unshift(member);
  },
  setMessage(state, message) {
    state.message = message;
  },
  updateOnline(state, [uuid, status]) {
    state.onlines[uuid] = status;
  },
  updateMessageRead(state, { receiver_uuid, message_uuid }) {
    const index = state[receiver_uuid].findIndex(
      (msg) => msg.uuid === message_uuid
    );
    if (index !== -1) {
      state[receiver_uuid][index].is_read = true;
    }
  },
  searchMessages(state, search) {
    if (!search) {
      state[state.currentChatId] = [];
      state[state.currentChatId] = state.currentChatCopy;
    }

    if (!state.currentChatCopy.length) {
      state.currentChatCopy = state[state.currentChatId];
    }

    state[state.currentChatId] = state[state.currentChatId].filter((msg) =>
      msg.message_text.toLowerCase().includes(search.toLowerCase())
    );
  },
  setGettingMessages(state, [status, increment]) {
    state.gettingMessages = status;
    if (increment) {
      state.nextPage++;
    }
  },
};

export const getters = {
  getMessages: (state) => () => state[state.currentChatId] || [],
  userOnline: (state) => () => {
    if (!state.currentChatId) return false;
    return state.onlines[state.currentChatId] || false;
  },
  gettingMessages: (state) => () => state.gettingMessages,
};
