<template>
  <router-view></router-view>
  <Toast />
  <Loading />
</template>

<script>
import Toast from '@/components/Toast.vue'
import Loading from './components/loading.vue'
export default {
  name: 'App',
  components: {
    Toast,
    Loading
  }
}
</script>
